import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer-sec " id="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="footer-icons d-flex">
                <a href="https://www.facebook.com/profile.php?id=100092655880834">
                  <i className="bi bi-facebook"></i>
                </a>

                <a
                  href="https://www.linkedin.com/company/elorca/"
                  className="wow fadeInDown"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
                <a href="https://instagram.com/elorca_the_orca_of_electronics?igshid=OGQ5ZDc2ODk2ZA==">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="www.hr@techwalnut.com" className="wow fadeInDown">
                  <i className="bi bi-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
