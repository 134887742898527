import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Quote.css";
import PopupModal from "./PopupModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function Quote() {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.quotable.io/random");
      setQuote(response.data.content);
      setAuthor(response.data.author);
    } catch (error) {
      console.error("Error fetching quote:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNextQuote = () => {
    setShowModal(true);
    setShowEmailInput(false);
  };

  const handleOpenPopup = () => {
    setShowModal(true);
    setShowEmailInput(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchData();
  };

  const handleYesClick = () => {
    setShowEmailInput(true);
  };

  const handleNoClick = () => {
    handleCloseModal();
    setShowEmailInput(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`${quote} - ${author}`);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000); 
  };

  return (
    <>
      <h3 className="h2 fw-bold mb-3 mt-5 text-center">Random Quote Generator</h3>
      <h5 className="h5 mb-3 mt-1 text-center">
        Choose Your Quote: Explore wisdom tailored to your preference!
        <span
          onClick={handleOpenPopup}
          style={{ cursor: "pointer", color: "blue" }}
        >
          Click Here
        </span>
      </h5>
      <div className="quote-container text-center">
        <div
          className="quote-box p-5 rounded-3"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="quote-content">
                <div className="copy-icon-container">
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-icon"
                    onClick={handleCopyClick}
                  />
                  {showTooltip && <div className="tooltip">Copied to clipboard!</div>}
                </div>
                {quote && <p className="quote">{`"${quote}"`}</p>}
                {author && <p className="author">{`${author}`}</p>}
              </div>
              <button className="next-quote-btn" onClick={handleNextQuote}>
                Next Quote
              </button>
            </>
          )}
        </div>

        {showModal && (
          <PopupModal
            onClose={handleCloseModal}
            onYesClick={handleYesClick}
            onNoClick={handleNoClick}
            showEmailInput={showEmailInput}
          />
        )}
      </div>
    </>
  );
}

export default Quote;
